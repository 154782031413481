import React from "react";
import { Link, withPrefix } from "gatsby";
import Layout from "../parts/Layout";
import { NextPageLink } from "../parts/NextPageLink";
import { PageComponentProps } from "../../types/types";

// @ts-ignore
import KeySignatureEMajorDn from "../../images/svg-dn/Clairnote-keysignature-e-major.svg";
// @ts-ignore
import KeySignatureEMajorSn from "../../images/svg-sn/Clairnote-keysignature-e-major.svg";

// @ts-ignore
import KeySignatureAFlatMajorDn from "../../images/svg-dn/Clairnote-keysignature-a-flat-major.svg";
// @ts-ignore
import KeySignatureAFlatMajorSn from "../../images/svg-sn/Clairnote-keysignature-a-flat-major.svg";

// @ts-ignore
import KeySignatureFMinorDn from "../../images/svg-dn/Clairnote-keysignature-f-minor.svg";
// @ts-ignore
import KeySignatureFMinorSn from "../../images/svg-sn/Clairnote-keysignature-f-minor.svg";

// @ts-ignore
import KeySignatureDPhrygianDn from "../../images/svg-dn/Clairnote-keysignature-d-phrygian.svg";
// @ts-ignore
import KeySignatureDPhrygianSn from "../../images/svg-sn/Clairnote-keysignature-d-phrygian.svg";

import * as pageStyles from "../global-styles/page.module.css";

export const KeySignatures = (props: PageComponentProps) => {
  const title = "Key Signatures";
  const description = `Discussion and illustration of key signatures in ${props.clairnoteName} music notation. They provide supplemental info and do not make reading notes more difficult.`;
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <div>
          <p>
            {props.clairnoteName} has a key signature system that communicates
            the same types of things conveyed by traditional key signatures.
            However, they play a much more minimal role when it comes to reading
            notes on the staff. With traditional notation you must keep the
            current key signature in mind at all times in order to play the
            right notes, but this is not the case with {props.clairnoteName}.
            For example, a piano or guitar player could ignore the key signature
            and still play the right notes based on their position on staff
            alone.
          </p>
          <p>
            {props.clairnoteName}’s key signatures help musicians know what to
            expect. They convey the same things as traditional key signatures:
          </p>
          <ul>
            <li>Which notes are in the key.</li>
            <li>
              The names of the notes in the key, whether they are sharp, flat,
              or natural.
            </li>
          </ul>
          <p>
            They also provide some information that is not conveyed by
            traditional key signatures:
          </p>
          <ul>
            <li>Which note is the tonic note.</li>
            <li>
              Whether the key is major, minor, or{" "}
              <a
                href="http://en.wikipedia.org/wiki/Musical_mode"
                rel="external"
              >
                modal
              </a>
              .
            </li>
          </ul>
          <p>
            Here is an illustration of an E major key signature (four sharps):
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={props.dn ? KeySignatureEMajorDn : KeySignatureEMajorSn}
              alt={
                "Key of E major in " + props.clairnoteName + " music notation"
              }
            />
          </div>
          <h2 className="title is-5">
            Reading {props.clairnoteName}
            's Key Signatures
          </h2>
          <p>
            The stack of hollow and solid dots in the key signature concisely
            indicates which notes are in the key. In the illustrations, notice
            how the dots correspond to the notes in the key that are shown on
            the staff. Solid dots correspond to{" "}
            {props.dn ? "solid notes / staff positions" : "line notes"} while
            hollow dots correspond to{" "}
            {props.dn ? "hollow notes / staff positions" : "space notes"}.
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={
                props.dn ? KeySignatureAFlatMajorDn : KeySignatureAFlatMajorSn
              }
              alt={
                "Key of A Flat major in " +
                props.clairnoteName +
                " music notation"
              }
            />
          </div>
          <p>
            The lowest dot indicates the tonic note in the key, and the pattern
            of hollow and solid dots conveys whether the key is major, minor, or
            modal. We know the illustration above shows the key of A-flat
            because the lowest dot corresponds to the tonic note A-flat. Also,
            it is A-flat <em>major</em> because major key signatures always have
            a 3-4 pattern – 3 dots of one kind followed by 4 of the other
            (ascending from bottom to top of the stack).
          </p>
          <p>
            The key signature shown below has four flats like the one above, but
            we can tell that it is an F minor key signature because the lowest
            dot corresponds to the tonic note F, and the dot pattern is 2-3-2,
            the pattern for minor key signatures. As you can see, in traditional
            notation these two key signatures are the same four flats. They
            don't indicate the tonic note or whether the key is major, minor, or
            modal.
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={props.dn ? KeySignatureFMinorDn : KeySignatureFMinorSn}
              alt={
                "Key of F minor in " + props.clairnoteName + " music notation"
              }
            />
          </div>
          <p>
            A "tail" connected to the left side of a dot indicates a note that
            is sharp or flat. A tail that moves downward (from left to right)
            indicates a flat note. Conversely, a tail that moves upward
            indicates a sharp. You can think of the tails as the trace of the
            dots’ movement as they moved into position in the stack of dots
            (either up/sharp or down/flat). Kind of like meteors in the night
            sky. This symbolism is similar to {props.clairnoteName}
            's{" "}
            <Link
              to={props.urlDir + "accidental-signs/"}
              title="Accidental Signs"
            >
              accidental signs
            </Link>
            .
          </p>
          <p>
            Note that these sharp and flat indications in the key signature are
            not like the sharps and flats in traditional key signatures. You do
            not need to remember them and they do not alter how you play the
            notes on the staff. They simply indicate the <em>names</em> of notes
            in the current key, clarifying the names of notes like the black
            keys on a piano that have two different names but have the same
            pitch (i.e. they are enharmonically equivalent in 12-tone equal
            temperament tuning).
            <sup id="ftnref1" className={pageStyles.footnoteRef}>
              <a href="#ftn1">[1]</a>
            </sup>{" "}
            This makes it possible to use the standard{" "}
            <Link
              to={props.urlDir + "names-of-notes-and-intervals/"}
              title="Names of Notes and Intervals"
            >
              Names of Notes and Intervals
            </Link>
            . (Musicians who are using an alternative nomenclature that does not
            differentiate between sharps and flats, can ignore or omit the tails
            that indicate the sharp or flat notes in the key.)
          </p>
          <p>
            For example, imagine you see a note on the staff that could be
            called either a B-flat or an A-sharp. You already know what note to
            play based on its position on the staff but what if you want to know
            its name? If it has no{" "}
            <Link
              to={props.urlDir + "accidental-signs/"}
              title="Accidental Sign"
            >
              accidental sign
            </Link>{" "}
            this tells you it is in the current key and you can simply check the
            key signature to find out whether it is named B-flat or A-sharp. You
            actually only need to know whether the key contains sharps or flats
            because standard keys contain one or the other but not both.
          </p>
          <p>
            Counting the number of tails tells you the number of sharps or flats
            in the key signature, which corresponds to the position of the key
            on the{" "}
            <a
              href="https://en.wikipedia.org/wiki/Circle_of_fifths"
              target="_blank"
              rel="noopener noreferrer"
            >
              circle/spiral of fifths
            </a>
            . This is just like counting the sharps and flats in traditional key
            signatures.
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={props.dn ? KeySignatureDPhrygianDn : KeySignatureDPhrygianSn}
              alt={
                "Key of D phrygian in " +
                props.clairnoteName +
                " music notation"
              }
            />
          </div>
          <p>
            In the illustration above the pattern of the dots (1-4-2) indicates
            that the music is not major or minor but{" "}
            <a href="http://en.wikipedia.org/wiki/Musical_mode" rel="external">
              modal
            </a>{" "}
            (in this case D phrygian). Each mode has its own dot pattern.
          </p>
          <h2 className="title is-5">Comprehensive Comparisons</h2>
          <p>
            This video shows all the keys and key signatures, in circle of
            fifths order, for both {props.clairnoteName} and traditional
            notation, illustrating their different approaches.
          </p>
          <div className={pageStyles.imageContainer}>
            <figure>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                style={{ height: "380px" }}
                className={pageStyles.videoBoxed}
                controls={true}
                preload="auto"
                width="750"
                height="380"
              >
                <source
                  src={withPrefix(
                    "video/Clairnote-key-signatures-" +
                      (props.sn ? "SN" : "DN") +
                      ".webm"
                  )}
                  type="video/webm"
                />
                <source
                  src={withPrefix(
                    "video/Clairnote-key-signatures-" +
                      (props.sn ? "SN" : "DN") +
                      ".mp4"
                  )}
                  type="video/mp4"
                />
                Your browser does not support the <code>video</code> element.
              </video>
              <figcaption className={pageStyles.caption}>
                Different Keys and Key Signatures in {props.clairnoteName} and
                Traditional Notation
              </figcaption>
            </figure>
          </div>
          <p>
            This{" "}
            <a
              href={withPrefix(
                "pdf/" +
                  (props.sn ? "sn" : "dn") +
                  "/Clairnote-Key-Signatures.pdf"
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              PDF file
            </a>{" "}
            is a comprehesive overview of all the key signatures (major, minor,
            and modal) in both {props.clairnoteName} and traditional notation.
            It was produced from this{" "}
            <a href={withPrefix("pdf-ly/Clairnote-Key-Signatures.ly")}>
              LilyPond file
            </a>
            .
          </p>
          <p>
            Note that to prevent collisions between the dots and the tails, the
            stacks of dots ascend moving to the right in flat keys and to the
            left in sharp keys. This also gives sharp and flat key signatures a
            more distinct and easily recognizable appearance.
          </p>

          <p>
            {props.clairnoteName}
            's key signatures work hand in hand with its accidental signs.
          </p>

          <NextPageLink
            title="Accidental Signs"
            to={props.urlDir + "accidental-signs/"}
          />
          <div className={pageStyles.footnotes}>
            <ol>
              <li id="ftn1">
                This assumes the standard tuning system of 12-tone equal
                temperament. In other tuning systems the pitch of
                "enharmonically equivalent" notes may differ slightly. For a
                performer these differences are a matter of minute adjustments
                in intonation. In that case {props.clairnoteName}'s key
                signatures and accidental signs help indicate these subtle
                shifts in intonation, along with the different names of the
                notes. See the{" "}
                <a
                  href="http://musicnotation.org/tutorials/enharmonic-equivalents/"
                  target="_blank"
                  rel="external noopener noreferrer"
                >
                  Enharmonic Equivalents
                </a>{" "}
                tutorial on the Music Notation Project's site.{" "}
                <a className={pageStyles.footnoteReturn} href="#ftnref1">
                  Return
                </a>
              </li>
            </ol>
          </div>
        </div>
      </article>
    </Layout>
  );
};
