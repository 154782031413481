import { PageProps } from "gatsby";
import React from "react";

import { KeySignatures } from "../components/pages/KeySignatures";

import { snProps } from "../js/utils";

const KeySignaturesPage = (props: PageProps) => (
  <KeySignatures {...{ ...snProps, ...props }} />
);

export default KeySignaturesPage;
